import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import allPostStyles from './featured_posts.module.scss'
import CategoryBox from "./Blog/category_box"

const AllPosts = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulPost (
            sort: {
                fields: publishedData,
                order: DESC
            }
            ) {
                nodes {
                    id
                    title
                    slug
                    excerpt {excerpt}
                    featuredImage {
                        file {
                            url
                        }
                    }
                    publishedData(formatString: "MMMM Do, YYYY")
                    postTags
                }
            }
        } 
    `)
    return (
        <div className={allPostStyles.allPostContainer}>
            <div className={allPostStyles.colContainerLeft}>
                <CategoryBox
                    category={'Home Field Advantage'}
                    posts={data.allContentfulPost.nodes}
                    emoji={`\u{1F3DF}`}
                />
               <CategoryBox
                    category={'QBs'}
                    emoji={`\u{2604}`}
                    posts={data.allContentfulPost.nodes}
                />
               <CategoryBox
                    category={'Models'}
                    posts={data.allContentfulPost.nodes}
                    emoji={`\u{1F4CA}`}
                />
               <CategoryBox
                    category={'Expected Points Added'}
                    posts={data.allContentfulPost.nodes}
                    emoji={`\u{1F4C8}`}
                />
            </div>
            <div className={allPostStyles.colContainerRight}>
                <CategoryBox
                    category={'All Posts'}
                    posts={data.allContentfulPost.nodes}
                    emoji={`\u{1F4DA}`}
                />
            </div>
        </div>
    )
}

export default AllPosts