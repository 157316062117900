import React from "react"
import { Link } from "gatsby"

import postStyles from './single_post.module.scss'

const SinglePost = ({ title, slug, postDate }) => {

    return (
        <Link 
            className={postStyles.postLinkWrapper}
            to={`/analysis/${slug}/`}
        >
            <div className={postStyles.postContainer}>
                <div className={postStyles.postContainerInner}>
                    <p className={postStyles.postTitle}>
                        {title}
                    </p>
                    <p className={postStyles.postDate}>
                        {postDate}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default SinglePost