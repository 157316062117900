import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import allPostStyles from './featured_posts.module.scss'
import FeaturedCard from "./Blog/featured_card"

const FeaturedPosts = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulPost (
            sort: {
                fields: publishedData,
                order: DESC
            }
            filter: {
                featured: {
                  eq:true
                }
            }
            ) {
                nodes {
                    id
                    title
                    slug
                    excerpt {excerpt}
                    featuredImage {
                        file {
                            url
                        }
                    }
                    publishedData(formatString: "MMMM Do, YYYY")
                    postTags
                }
            }
        } 
    `)
    return (
        <div className={allPostStyles.allPostContainer}>
            <h3 className={allPostStyles.sectionHeading}>
                Featured Posts&nbsp;&nbsp;&nbsp;<span className={allPostStyles.emojiSpan}>{`\u{2728}`}</span>
            </h3>
            <div className={allPostStyles.allPosts}>
                {data.allContentfulPost.nodes.map(node => {
                    return (
                        <FeaturedCard
                            key={node.id}
                            id={node.id}
                            title={node.title}
                            slug={node.slug}
                            postDate={node.publishedData}
                            excerpt={node.excerpt.excerpt}
                            featuredImage={node.featuredImage.file.url}
                            postTags={node.postTags}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default FeaturedPosts