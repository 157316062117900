import React from "react"
import { navigate } from "gatsby-link"

import Layout from '../components/layout'
import Head from '../components/head'
import AllPosts from "../components/analysis_posts"
import FeaturedPosts from "../components/featured_posts"

import pageTitleStyles from '../components/page_titles.module.scss'

const NotFoundPage = () => {

    const returnButtons = [

        {
            'id' : 'games',
            'name' : 'Games',
            'route' : '/games'
        },

        {
            'id' : 'teams',
            'name' : 'Teams',
            'route' : '/nfl-power-rankings'
        },

        {
            'id' : 'qbs',
            'name' : 'QBs',
            'route' : '/qb-rankings'
        },

        {
            'id' : 'analysis',
            'name' : 'Analysis',
            'route' : '/analysis'
        },

    ]

    return (
      <Layout>
        <Head  title="404: Page Not Found" pageDescription="We couldn't find what you were looking for"/>
        <h1 className={pageTitleStyles.nf_title}>
            404
        </h1>
        <h2 className={pageTitleStyles.nf_subHeader}>
            We coudn't find the page you were looking for
        </h2>
        <div className={pageTitleStyles.returnButtonContainer}>
            {returnButtons.map((item) => {
                return (
                    <button
                        className={pageTitleStyles.returnButton}
                        key={item.id}
                        onClick={()=> {navigate(item.route)}}
                    >
                        {item.name}
                    </button>
                )
            })}
        </div>
        <FeaturedPosts />
        <AllPosts />
      </Layout>
  )
}

export default NotFoundPage

