import React from "react"

import categoryStyles from './category_box.module.scss'
import allPostStyles from '../featured_posts.module.scss'
import SinglePost from "./single_post"

const CategoryBox = ({category, posts, emoji}) => {

    const filteredPosts = []
    posts.forEach(post => {
        if (category === 'All Posts') {
            filteredPosts.push(post)
        } else {
            post.postTags.forEach(tag => {
                if (tag === category) {
                    filteredPosts.push(post)
                }
            })
        }
    })

    return (
        <div className={categoryStyles.categoryWrapper}>
            <p className={allPostStyles.sectionHeading}>
                {category}&nbsp;&nbsp;&nbsp;<span className={categoryStyles.emojiSpan}>{emoji}</span>
            </p>
            <div className={categoryStyles.postContainer}>
                {filteredPosts.map(post => {
                    return (
                        <SinglePost
                            key={post.id}
                            title={post.title}
                            slug={post.slug}
                            postDate={post.publishedData}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default CategoryBox