import React from "react"
import { Link } from "gatsby"

import cardStyles from './featured_card.module.scss'

const FeaturedCard = ({id, title, slug, postDate, excerpt, featuredImage, postTags}) => {

    return (
        <Link 
            className={cardStyles.cardLinkWrapper}
            to={`/analysis/${slug}/`}
            key={id}
        >
            <div className={cardStyles.cardContainer}>
                <div className={cardStyles.featuredImageContainer}>
                    <div className={cardStyles.featuredImageOverlay}>
                    </div>
                    <img 
                        className={cardStyles.featuredImage}
                        src={featuredImage}
                        alt='The posts feature'
                    ></img>
                    <div className={cardStyles.tagContainer}>
                        {postTags.map(node => {
                            return (
                                <div className={cardStyles.tagBox} key={`${id}_${node}`}>
                                    <p className={cardStyles.tag}>
                                        {node}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={cardStyles.postInfoContainer}>
                    <div className={cardStyles.dateBox}>
                        <p className={cardStyles.date}>
                            {postDate}
                        </p>
                    </div>
                    <h3 className={cardStyles.postTitle}>
                        {title}
                    </h3>
                    <div className={cardStyles.excerptContainer}>
                        <p className={cardStyles.excerpt}>
                            {excerpt}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default FeaturedCard